import React, { useState, useCallback } from "react";
import { useStyles } from "../../assets/styles.js";
import {
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  Select,
  Avatar,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  ListItemText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DvrIcon from "@mui/icons-material/Dvr";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "../../redux/Actions/adminAction.js";

export const AddSubAdmin = ({ dispatch, isLoading }) => {
  var classes = useStyles();
  const navigate = useNavigate();
  // const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const [state, setState] = useState({
    email: "",
    name: "",
    type: "",
    showPassword: "",
    permission: {
      astrologer: {
        isPermited: false,
        addAstrologer: false,
        listOfAstrologer: {
          isPermited: false,
          updateStatus: false,
          updateChatStatus: false,
          updateCallStatus: false,
          markAsTop: false,
          editAstrologer: false,
          viewAstrologer: false,
          deleteAstrologer: false,
        },
        topAstrologers: {
          isPermited: false,
          delete: false,
        },
      },
      customer: {
        isPermited: false,
        addCustomer: false,
        listOfCustomer: {
          isPermited: false,
          updateCustomerStatus: false,
          addRecharge: false,
          editCustomer: false,
          viewCustomer: false,
          deleteCustomer: false,
        },
        chatHistory: {
          isPermited: false,
          viewChatMessages: false,
          viewChatHistoryData: false,
          delete: false,
          addReview: false,
          download: false,
        },
        callHistory: {
          isPermited: false,
          viewCallHistoryData: false,
          delete: false,
          addReview: false,
          download: false,
        },
        rechargeHistory: {
          isPermited: false,
          addRecharge: false,
          delete: false,
        },
        walletHistory: {
          isPermited: false,
        },
      },
      callDiscussion: {
        isPermited: false,
        viewCallDiscussion: {
          isPermited: false,
          edit: false,
          delete: false,
          add: false,
        },
      },
      courses: {
        isPermited: false,
        coursesList: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        addCourses: false,
        demoClass: {
          isPermited: false,
          adminStatus: false,
          classStatus: false,
          status: false,
          pdf: false,
          view: false,
          edit: false,
          delete: false,
        },
        liveClass: {
          isPermited: false,
          adminStatus: false,
          classStatus: false,
          status: false,
          pdf: false,
          view: false,
          edit: false,
          delete: false,
          classList: false,
          mcqQuestions: false,
          add: false,
        },
        mcqList: false,
        demoClassHistory: {
          isPermited: false,
          status: false,
          view: false,
          delete: false,
        },
        liveCourseHistory: {
          isPermited: false,
          status: false,
          view: false,
          delete: false,
        },
      },
      blogs: {
        isPermited: false,
        viewBlogs: {
          isPermited: false,
          edit: false,
          delete: false,
          add: false,
          status: false,
        },
      },
      blogsCategory: {
        isPermited: false,
        edit: false,
        delete: false,
        add: false,
      },
      banners: {
        isPermited: false,
        astrologerTrainingbanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        astrologerbanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        coursesbanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        redirectBanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        callChatBanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        ecommerceBanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        productBanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },
        poojaBanners: {
          isPermited: false,
          status: false,
          edit: false,
          delete: false,
          add: false,
        },

      },

    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleError = (field, message) => {
    setError((prevError) => ({ ...prevError, [field]: message }));
  };

  const updateState = (data) => {
    setState((prevState) => {
      const newData = { ...prevState, ...data };
      return newData;
    });
  };

  const validation = () => {
    var isValid = true;

    if (!email) {
      handleError("email", "Please Enter User Email");
      isValid = false;
    }
    if (!name) {
      handleError("name", "Please Enter Name");
      isValid = false;
    }
    if (!password) {
      handleError("password", "Please Enter password");
      isValid = false;
    }
    if (!type) {
      handleError("type", "Please input Type");
      isValid = false;
    }

    // if (!permission) {
    //     handleError("permission", "Please input Permission");
    //     isValid = false;
    //   }
    return isValid;
  };

  const onAdd = () => {
    handleReset();
    navigate("/display-sub-admin");
  };

  const handleSubmit = async () => {
    console.log("Testing 1");
    if (validation()) {
      const data = {
        email: email,
        name: name,
        password: password,
        type: type,
        permissions: permission,
      };
      dispatch(Actions.subadminAdd({ data, onAdd }));
      console.log("Testing");
    }
  };

  const handleReset = useCallback(() => {
    setPassword("");
    updateState({
      email: "",
      name: "",
      type: "",
      showPassword: "",
      permission: {
        astrologer: {
          isPermited: false,
          addAstrologer: false,
          listOfAstrologer: {
            isPermited: false,
            updateStatus: false,
            updateChatStatus: false,
            updateCallStatus: false,
            markAsTop: false,
            editAstrologer: false,
            viewAstrologer: false,
            deleteAstrologer: false,
          },
          topAstrologers: {
            isPermited: false,
            delete: false,
          },
        },
        customer: {
          isPermited: false,
          addCustomer: false,
          listOfCustomer: {
            isPermited: false,
            updateCustomerStatus: false,
            addRecharge: false,
            editCustomer: false,
            viewCustomer: false,
            deleteCustomer: false,
          },
          chatHistory: {
            isPermited: false,
            viewChatMessages: false,
            viewChatHistoryData: false,
            delete: false,
            addReview: false,
            download: false,

          },
          callHistory: {
            isPermited: false,
            viewCallHistoryData: false,
            delete: false,
            addReview: false,
            download: false,
          },
          rechargeHistory: {
            isPermited: false,
            addRecharge: false,
            delete: false,
            download: false,
          },
        },
        callDiscussion: {
          isPermited: false,
          edit: false,
          delete: false,
          add: false,
        },
        courses: {
          isPermited: false,
          coursesList: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          addCourses: false,
          demoClass: {
            isPermited: false,
            adminStatus: false,
            classStatus: false,
            status: false,
            pdf: false,
            view: false,
            edit: false,
            delete: false,
          },
          liveClass: {
            isPermited: false,
            adminStatus: false,
            classStatus: false,
            status: false,
            pdf: false,
            view: false,
            edit: false,
            delete: false,
            classList: false,
            mcqQuestions: false,
            add: false,
          },
          mcqList: false,
          demoClassHistory: {
            isPermited: false,
            status: false,
            view: false,
            delete: false,
          },
          liveCourseHistory: {
            isPermited: false,
            status: false,
            view: false,
            delete: false,
          },
        },
        blogs: {
          isPermited: false,
          viewBlogs: {
            isPermited: false,
            edit: false,
            delete: false,
            add: false,
            status: false,
          },
        },
        blogsCategory: {
          isPermited: false,
          edit: false,
          delete: false,
          add: false,
        },
        banners: {
          isPermited: false,
          astrologerTrainingbanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          astrologerbanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          coursesbanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          redirectBanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          callChatBanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          ecommerceBanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          productBanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },
          poojaBanners: {
            isPermited: false,
            status: false,
            edit: false,
            delete: false,
            add: false,
          },

        },
      },
    });
  });

  const handlePermissionChange = (key) => {
    setState((prevState) => {
      const [mainKey, subKey] = key.split("."); // split key for nested properties
      const currentPermission =
        prevState.permission.astrologer[mainKey][subKey];
      return {
        ...prevState,
        permission: {
          ...prevState.permission,
          astrologer: {
            ...prevState.permission.astrologer,
            [mainKey]: {
              ...prevState.permission.astrologer[mainKey],
              [subKey]: !currentPermission,
            },
          },
        },
      };
    });
  };

  const updatePermission = (path, value) => {
    setState((prevState) => {
      const updatedState = { ...prevState };
      const keys = path.split(".");

      let current = updatedState;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      current[keys[keys.length - 1]] = value;

      return updatedState;
    });
  };

  const { email, name, permission, type } = state;

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <div className={classes.headingContainer}>
              <div className={classes.heading}>Add SubAdmin</div>
              <div
                onClick={() => navigate("/display-sub-admin")}
                className={classes.addButton}
              >
                <DvrIcon />
                <div className={classes.addButtontext}>Display SubAdmins </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="User Email"
              error={error.email ? true : false}
              helperText={error.email}
              value={email}
              onFocus={() => handleError("email", null)}
              onChange={(e) => updateState({ email: e.target.value })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Name"
              error={error.name ? true : false}
              helperText={error.name}
              value={name}
              onFocus={() => handleError("name", null)}
              onChange={(e) => updateState({ name: e.target.value })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id="outlined-description-static"
              label="Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              variant="outlined"
              error={!!error.password}
              helperText={error.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      onMouseDown={(event) => event.preventDefault()} // Prevents focus loss
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type"
                value={type}
                error={error.type ? true : false}
                onFocus={() => handleError("type", null)}
                onChange={(e) => updateState({ type: e.target.value })}
              >
                <MenuItem disabled value={null}>
                  -Select Type-
                </MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="subadmin">Sub-Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          {type !== 'admin' && (
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <FormControl
                component="fieldset"
                style={{ marginLeft: "10px", marginLeft: "10px" }}
              >
                <FormLabel
                  component="legend"
                  style={{ fontSize: "20px", fontWeight: "500", color: "black" }}
                >
                  Permissions
                </FormLabel>

                {/* Astrologer Permisiions  */}

                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.astrologer.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.astrologer.isPermited}
                          onChange={() => {
                            if (permission.astrologer.isPermited) {
                              updatePermission("permission.astrologer", {
                                isPermited: false,
                                addAstrologer: false,
                                listOfAstrologer: {
                                  isPermited: false,
                                  updateStatus: false,
                                  updateChatStatus: false,
                                  updateCallStatus: false,
                                  markAsTop: false,
                                  editAstrologer: false,
                                  viewAstrologer: false,
                                  deleteAstrologer: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.astrologer.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Astrologer
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={"Add Astrologer"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.astrologer.isPermited}
                          checked={permission.astrologer.addAstrologer}
                          onChange={() => {
                            if (permission.astrologer.addAstrologer) {
                              updatePermission(
                                "permission.astrologer.addAstrologer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.addAstrologer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Astrologer"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.astrologer.listOfAstrologer.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.astrologer.isPermited}
                          checked={
                            permission.astrologer.listOfAstrologer.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer.isPermited
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer",
                                {
                                  isPermited: false,
                                  updateStatus: false,
                                  updateChatStatus: false,
                                  updateCallStatus: false,
                                  markAsTop: false,
                                  editAstrologer: false,
                                  viewAstrologer: false,
                                  deleteAstrologer: false,
                                }
                              );
                            } else {
                              updatePermission("permission.astrologer.listOfAstrologer.isPermited", true);
                            }
                          }}
                        />
                      }
                      label={"List of Astrologers"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={permission.astrologer.listOfAstrologer.updateStatus}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer.updateStatus
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer.updateStatus
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Update Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Update Chat Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          value={
                            permission.astrologer.listOfAstrologer
                              .updateChatStatus
                          }
                          checked={
                            permission.astrologer.listOfAstrologer
                              .updateChatStatus
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer
                                .updateChatStatus
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateChatStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateChatStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Update Chat Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Update Call Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer
                              .updateCallStatus
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer
                                .updateCallStatus
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateCallStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.updateCallStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Update Call Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Mark as top"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer
                              .markAsTop
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer
                                .markAsTop
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.markAsTop",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.markAsTop",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Mark as top"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.astrologer.listOfAstrologer.editAstrologer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer.editAstrologer
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer
                                .editAstrologer
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.editAstrologer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.editAstrologer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit Astrologer"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.astrologer.listOfAstrologer.viewAstrologer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer.viewAstrologer
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer
                                .viewAstrologer
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.viewAstrologer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.viewAstrologer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Astrologer"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.astrologer.listOfAstrologer.deleteAstrologer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.astrologer.isPermited ||
                            !permission.astrologer.listOfAstrologer.isPermited
                          }
                          checked={
                            permission.astrologer.listOfAstrologer
                              .deleteAstrologer
                          }
                          onChange={() => {
                            if (
                              permission.astrologer.listOfAstrologer.deleteAstrologer
                            ) {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.deleteAstrologer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.listOfAstrologer.deleteAstrologer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete Astrologer"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission?.astrologer?.topAstrologers?.isPermited} // Optional chaining added here
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission?.astrologer?.isPermited} // Optional chaining added here
                          checked={permission?.astrologer?.topAstrologers?.isPermited} // Optional chaining added here
                          onChange={() => {
                            if (permission?.astrologer?.topAstrologers?.isPermited) { // Optional chaining added here
                              updatePermission(
                                "permission.astrologer.topAstrologers",
                                {
                                  isPermited: false,
                                  delete: false,
                                }
                              );
                            } else {
                              updatePermission("permission.astrologer.topAstrologers.isPermited", true);
                            }
                          }}
                        />
                      }
                      label={"Top Astrologers"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={permission?.astrologer?.topAstrologers?.delete} // Optional chaining added here
                      control={
                        <Checkbox
                          disabled={
                            !permission?.astrologer?.isPermited || // Optional chaining added here
                            !permission?.astrologer?.topAstrologers?.isPermited // Optional chaining added here
                          }
                          checked={permission?.astrologer?.topAstrologers?.delete} // Optional chaining added here
                          onChange={() => {
                            if (permission?.astrologer?.topAstrologers?.delete) { // Optional chaining added here
                              updatePermission(
                                "permission.astrologer.topAstrologers.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.astrologer.topAstrologers.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>


                {/* Customer Permisiions  */}

                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.customer.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.customer.isPermited}
                          onChange={() => {
                            if (permission.customer.isPermited) {
                              updatePermission("permission.customer", {
                                isPermited: false,
                                addAstrologer: false,
                                listOfAstrologer: {
                                  isPermited: false,
                                  updateStatus: false,
                                  updateChatStatus: false,
                                  updateCallStatus: false,
                                  editAstrologer: false,
                                  viewAstrologer: false,
                                  deleteAstrologer: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.customer.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Customer
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* List Of Customer */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={"Add Customer"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.customer.isPermited}
                          checked={permission.customer.addCustomer}
                          onChange={() => {
                            if (permission.customer.addCustomer) {
                              updatePermission(
                                "permission.customer.addCustomer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.addCustomer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Customer"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.customer.listOfCustomer.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.customer.isPermited}
                          checked={
                            permission.customer.listOfCustomer.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer.isPermited
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer",
                                {
                                  isPermited: false,
                                  updateStatus: false,
                                  updateChatStatus: false,
                                  updateCallStatus: false,
                                  editAstrologer: false,
                                  viewAstrologer: false,
                                  deleteAstrologer: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"List of Customer"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={permission.customer.listOfCustomer.updateCustomerStatus}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.listOfCustomer.isPermited
                          }
                          checked={
                            permission.customer.listOfCustomer.updateCustomerStatus
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer.updateCustomerStatus
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer.updateCustomerStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.updateCustomerStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Update Customer Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add Recharge"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.listOfCustomer.isPermited
                          }
                          value={
                            permission.customer.listOfCustomer
                              .addRecharge
                          }
                          checked={
                            permission.customer.listOfCustomer
                              .addRecharge
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer
                                .addRecharge
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer.addRecharge",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.addRecharge",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Recharge"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.listOfCustomer.editCustomer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.listOfCustomer.isPermited
                          }
                          checked={
                            permission.customer.listOfCustomer.editCustomer
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer
                                .editCustomer
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer.editCustomer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.editCustomer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit Customer"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.listOfCustomer.viewCustomer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.listOfCustomer.isPermited
                          }
                          checked={
                            permission.customer.listOfCustomer.viewCustomer
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer
                                .viewCustomer
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer.viewCustomer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.viewCustomer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Customer"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.listOfCustomer.deleteCustomer
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.listOfCustomer.isPermited
                          }
                          checked={
                            permission.customer.listOfCustomer
                              .deleteCustomer
                          }
                          onChange={() => {
                            if (
                              permission.customer.listOfCustomer.deleteCustomer
                            ) {
                              updatePermission(
                                "permission.customer.listOfCustomer.deleteCustomer",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.listOfCustomer.deleteCustomer",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete Customer"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Chat History of Customer */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.customer.chatHistory.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.customer.isPermited}
                          checked={
                            permission.customer.chatHistory.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory.isPermited
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory",
                                {
                                  isPermited: false,
                                  viewChatMessages: false,
                                  viewChatHistoryData: false,
                                  delete: false,
                                  addReview: false,
                                  download: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Chat History"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={permission.customer.chatHistory.viewChatMessages}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.chatHistory.isPermited
                          }
                          checked={
                            permission.customer.chatHistory.viewChatMessages
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory.viewChatMessages
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory.viewChatMessages",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.viewChatMessages",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Chat Messages"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"View Chat History Data"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.chatHistory.isPermited
                          }
                          value={
                            permission.customer.chatHistory
                              .viewChatHistoryData
                          }
                          checked={
                            permission.customer.chatHistory
                              .viewChatHistoryData
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory
                                .viewChatHistoryData
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory.viewChatHistoryData",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.viewChatHistoryData",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Chat History Data"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.chatHistory.delete
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.chatHistory.isPermited
                          }
                          checked={
                            permission.customer.chatHistory.delete
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory
                                .delete
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.chatHistory.addReview
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.chatHistory.isPermited
                          }
                          checked={
                            permission.customer.chatHistory.addReview
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory
                                .addReview
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory.addReview",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.addReview",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Review"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={
                        permission.customer.chatHistory.download
                      }
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.customer.isPermited ||
                            !permission.customer.chatHistory.isPermited
                          }
                          checked={
                            permission.customer.chatHistory
                              .download
                          }
                          onChange={() => {
                            if (
                              permission.customer.chatHistory.download
                            ) {
                              updatePermission(
                                "permission.customer.chatHistory.download",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.chatHistory.download",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Download CSV"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Call History of Customer */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission?.customer?.callHistory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission?.customer?.isPermited}
                          checked={permission?.customer?.callHistory?.isPermited}
                          onChange={() => {
                            if (permission?.customer?.callHistory?.isPermited) {
                              updatePermission(
                                "permission.customer.callHistory",
                                {
                                  isPermited: false,
                                  viewCallHistoryData: false,
                                  delete: false,
                                  addReview: false,
                                  download: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.customer.callHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Call History"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"View Chat History Data"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.callHistory?.isPermited
                          }
                          value={permission?.customer?.callHistory?.viewCallHistoryData}
                          checked={permission?.customer?.callHistory?.viewCallHistoryData}
                          onChange={() => {
                            if (permission?.customer?.callHistory?.viewCallHistoryData) {
                              updatePermission(
                                "permission.customer.callHistory.viewCallHistoryData",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.callHistory.viewCallHistoryData",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Call History Data"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={permission?.customer?.callHistory?.delete}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.callHistory?.isPermited
                          }
                          checked={permission?.customer?.callHistory?.delete}
                          onChange={() => {
                            if (permission?.customer?.callHistory?.delete) {
                              updatePermission(
                                "permission.customer.callHistory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.callHistory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={permission?.customer?.callHistory?.addReview}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.callHistory?.isPermited
                          }
                          checked={permission?.customer?.callHistory?.addReview}
                          onChange={() => {
                            if (permission?.customer?.callHistory?.addReview) {
                              updatePermission(
                                "permission.customer.callHistory.addReview",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.callHistory.addReview",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Review"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={permission?.customer?.callHistory?.download}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.callHistory?.isPermited
                          }
                          checked={permission?.customer?.callHistory?.download}
                          onChange={() => {
                            if (permission?.customer?.callHistory?.download) {
                              updatePermission(
                                "permission.customer.callHistory.download",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.callHistory.download",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Download CSV"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Recharge History of Customer */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission?.customer?.rechargeHistory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission?.customer?.isPermited}
                          checked={permission?.customer?.rechargeHistory?.isPermited}
                          onChange={() => {
                            if (permission?.customer?.rechargeHistory?.isPermited) {
                              updatePermission(
                                "permission.customer.rechargeHistory",
                                {
                                  isPermited: false,
                                  addRecharge: false,
                                  delete: false,

                                }
                              );
                            } else {
                              updatePermission(
                                "permission.customer.rechargeHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Recharge History"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >

                  <div>
                    <FormControlLabel
                      value={permission?.customer?.rechargeHistory?.addRecharge}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.rechargeHistory?.isPermited
                          }
                          checked={permission?.customer?.rechargeHistory?.addRecharge}
                          onChange={() => {
                            if (permission?.customer?.rechargeHistory?.addRecharge) {
                              updatePermission(
                                "permission.customer.rechargeHistory.addRecharge",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.rechargeHistory.addRecharge",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Recharge"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={permission?.customer?.rechargeHistory?.delete}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission?.customer?.isPermited ||
                            !permission?.customer?.rechargeHistory?.isPermited
                          }
                          checked={permission?.customer?.rechargeHistory?.delete}
                          onChange={() => {
                            if (permission?.customer?.rechargeHistory?.delete) {
                              updatePermission(
                                "permission.customer.rechargeHistory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.customer.rechargeHistory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>

                </FormGroup>

                {/* Wallet History of Customer */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission?.customer?.walletHistory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission?.customer?.isPermited}
                          checked={permission?.customer?.walletHistory?.isPermited}
                          onChange={() => {
                            if (permission?.customer?.walletHistory?.isPermited) {
                              updatePermission(
                                "permission.customer.walletHistory",
                                {
                                  isPermited: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.customer.walletHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Wallet History"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* !--- Call Discussion ---! */}
                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.callDiscussion?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.callDiscussion?.isPermited}
                          onChange={() => {
                            if (permission.callDiscussion?.isPermited) {
                              updatePermission("permission.callDiscussion", {
                                isPermited: false,
                                viewCallDiscussion: {
                                  isPermited: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.callDiscussion.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontSize: "14px",
                          }}
                        >

                          Call Discussion
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.callDiscussion?.viewCallDiscussion?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.callDiscussion?.isPermited}
                          checked={
                            permission.callDiscussion?.viewCallDiscussion?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.callDiscussion?.viewCallDiscussion?.isPermited
                            ) {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion",
                                {
                                  isPermited: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Call Discussion"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.callDiscussion?.isPermited ||
                            !permission.callDiscussion?.viewCallDiscussion?.isPermited
                          }
                          value={permission.callDiscussion?.viewCallDiscussion?.edit}
                          checked={permission.callDiscussion?.viewCallDiscussion?.edit}
                          onChange={() => {
                            if (
                              permission.callDiscussion?.viewCallDiscussion?.edit
                            ) {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.callDiscussion?.isPermited ||
                            !permission.callDiscussion?.viewCallDiscussion?.isPermited
                          }
                          value={permission.callDiscussion?.viewCallDiscussion?.delete}
                          checked={permission.callDiscussion?.viewCallDiscussion?.delete}
                          onChange={() => {
                            if (
                              permission.callDiscussion?.viewCallDiscussion?.delete
                            ) {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.callDiscussion?.isPermited ||
                            !permission.callDiscussion?.viewCallDiscussion?.isPermited
                          }
                          checked={permission.callDiscussion?.viewCallDiscussion?.add}
                          onChange={() => {
                            if (
                              permission.callDiscussion?.viewCallDiscussion?.add
                            ) {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.callDiscussion.viewCallDiscussion.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* !--- Courses ---! */}

                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.courses?.isPermited}
                          onChange={() => {
                            if (permission.courses?.isPermited) {
                              updatePermission("permission.courses", {
                                isPermited: false,
                                coursesList: {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.courses.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontSize: "14px",
                          }}
                        >
                          Courses
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                {/* Courses List */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.coursesList?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses?.isPermited}
                          checked={
                            permission.courses?.coursesList?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.courses?.coursesList?.isPermited
                            ) {
                              updatePermission(
                                "permission.courses.coursesList",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.courses.coursesList.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Courses List"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.coursesList?.isPermited
                          }
                          value={permission.courses?.coursesList?.status}
                          checked={permission.courses?.coursesList?.status}
                          onChange={() => {
                            if (
                              permission.courses?.coursesList?.status
                            ) {
                              updatePermission(
                                "permission.courses.coursesList.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.coursesList.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.coursesList?.isPermited
                          }
                          value={permission.courses?.coursesList?.edit}
                          checked={permission.courses?.coursesList?.edit}
                          onChange={() => {
                            if (
                              permission.courses?.coursesList?.edit
                            ) {
                              updatePermission(
                                "permission.courses.coursesList.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.coursesList.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.coursesList?.isPermited
                          }
                          value={permission.courses?.coursesList?.delete}
                          checked={permission.courses?.coursesList?.delete}
                          onChange={() => {
                            if (
                              permission.courses?.coursesList?.delete
                            ) {
                              updatePermission(
                                "permission.courses.coursesList.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.coursesList.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.coursesList?.isPermited
                          }
                          checked={permission.courses?.coursesList?.add}
                          onChange={() => {
                            if (
                              permission.courses?.coursesList?.add
                            ) {
                              updatePermission(
                                "permission.courses.coursesList.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.coursesList.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Add Courses */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={"Add Courses"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses.isPermited}
                          checked={permission.courses.addCourses}
                          onChange={() => {
                            if (permission.courses.addCourses) {
                              updatePermission(
                                "permission.courses.addCourses",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.addCourses",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add Courses"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Demo Class */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.demoClass?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses?.isPermited}
                          checked={
                            permission.courses?.demoClass?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.isPermited
                            ) {
                              updatePermission(
                                "permission.courses.demoClass",
                                {
                                  isPermited: false,
                                  adminStatus: false,
                                  classStatus: false,
                                  status: false,
                                  pdf: false,
                                  view: false,
                                  edit: false,
                                  delete: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Demo Classes"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Admin Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          value={permission.courses?.demoClass?.adminStatus}
                          checked={permission.courses?.demoClass?.adminStatus}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.adminStatus
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.adminStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.adminStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Admin Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Class Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          value={permission.courses?.demoClass?.classStatus}
                          checked={permission.courses?.demoClass?.classStatus}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.classStatus
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.classStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.classStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Class Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          value={permission.courses?.demoClass?.status}
                          checked={permission.courses?.demoClass?.status}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.status
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"PDF"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          value={permission.courses?.demoClass?.pdf}
                          checked={permission.courses?.demoClass?.pdf}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.pdf
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.pdf",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.pdf",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"pdf"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"View"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          checked={permission.courses?.demoClass?.view}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.view
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.view",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.view",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          checked={permission.courses?.demoClass?.edit}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.edit
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClass?.isPermited
                          }
                          checked={permission.courses?.demoClass?.delete}
                          onChange={() => {
                            if (
                              permission.courses?.demoClass?.delete
                            ) {
                              updatePermission(
                                "permission.courses.demoClass.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClass.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delte"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Live Class */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.liveClass?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses?.isPermited}
                          checked={
                            permission.courses?.liveClass?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.isPermited
                            ) {
                              updatePermission(
                                "permission.courses.demoClass",
                                {
                                  isPermited: false,
                                  adminStatus: false,
                                  classStatus: false,
                                  status: false,
                                  pdf: false,
                                  view: false,
                                  edit: false,
                                  delete: false,
                                  classList: false,
                                  mcqQuestions: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Live Classes"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Admin Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          value={permission.courses?.liveClass?.adminStatus}
                          checked={permission.courses?.liveClass?.adminStatus}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.adminStatus
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.adminStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.adminStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Admin Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Class Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          value={permission.courses?.liveClass?.classStatus}
                          checked={permission.courses?.liveClass?.classStatus}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.classStatus
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.classStatus",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.classStatus",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Class Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          value={permission.courses?.liveClass?.status}
                          checked={permission.courses?.liveClass?.status}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.status
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"PDF"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          value={permission.courses?.liveClass?.pdf}
                          checked={permission.courses?.liveClass?.pdf}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.pdf
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.pdf",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.pdf",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"pdf"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"View"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          checked={permission.courses?.liveClass?.view}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.view
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.view",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.view",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          checked={permission.courses?.liveClass?.edit}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.edit
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          checked={permission.courses?.liveClass?.delete}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.delete
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"MCQ"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          checked={permission.courses?.liveClass?.mcqQuestions}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.mcqQuestions
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.mcqQuestions",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.mcqQuestions",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"MCQ Questions"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveClass?.isPermited
                          }
                          checked={permission.courses?.liveClass?.add}
                          onChange={() => {
                            if (
                              permission.courses?.liveClass?.add
                            ) {
                              updatePermission(
                                "permission.courses.liveClass.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveClass.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* MCQ List */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={"MCQ Questions"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses.isPermited}
                          checked={permission.courses.mcqList}
                          onChange={() => {
                            if (permission.courses.mcqList) {
                              updatePermission(
                                "permission.courses.mcqList",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.mcqList",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"MCQ Questions"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Demo Class History */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.demoClassHistory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses?.isPermited}
                          checked={
                            permission.courses?.demoClassHistory?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.courses?.demoClassHistory?.isPermited
                            ) {
                              updatePermission(
                                "permission.courses.demoClassHistory",
                                {
                                  isPermited: false,
                                  status: false,
                                  view: false,
                                  delete: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClassHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Demo Class Hisory"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClassHistory?.isPermited
                          }
                          value={permission.courses?.demoClassHistory?.status}
                          checked={permission.courses?.demoClassHistory?.status}
                          onChange={() => {
                            if (
                              permission.courses?.demoClassHistory?.status
                            ) {
                              updatePermission(
                                "permission.courses.demoClassHistory.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClassHistory.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"View"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClassHistory?.isPermited
                          }
                          value={permission.courses?.demoClassHistory?.view}
                          checked={permission.courses?.demoClassHistory?.view}
                          onChange={() => {
                            if (
                              permission.courses?.demoClassHistory?.view
                            ) {
                              updatePermission(
                                "permission.courses.demoClassHistory.view",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClassHistory.view",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.demoClassHistory?.isPermited
                          }
                          value={permission.courses?.demoClassHistory?.delete}
                          checked={permission.courses?.demoClassHistory?.delete}
                          onChange={() => {
                            if (
                              permission.courses?.demoClassHistory?.delete
                            ) {
                              updatePermission(
                                "permission.courses.demoClassHistory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.demoClassHistory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>

                </FormGroup>

                {/* Live Class History */}

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.courses?.liveCourseHistory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.courses?.isPermited}
                          checked={
                            permission.courses?.liveCourseHistory?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.courses?.liveCourseHistory?.isPermited
                            ) {
                              updatePermission(
                                "permission.courses.liveCourseHistory",
                                {
                                  isPermited: false,
                                  status: false,
                                  view: false,
                                  delete: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveCourseHistory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Live Class Hisory"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveCourseHistory?.isPermited
                          }
                          value={permission.courses?.liveCourseHistory?.status}
                          checked={permission.courses?.liveCourseHistory?.status}
                          onChange={() => {
                            if (
                              permission.courses?.liveCourseHistory?.status
                            ) {
                              updatePermission(
                                "permission.courses.liveCourseHistory.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveCourseHistory.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"View"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveCourseHistory?.isPermited
                          }
                          value={permission.courses?.liveCourseHistory?.view}
                          checked={permission.courses?.liveCourseHistory?.view}
                          onChange={() => {
                            if (
                              permission.courses?.liveCourseHistory?.view
                            ) {
                              updatePermission(
                                "permission.courses.liveCourseHistory.view",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveCourseHistory.view",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.courses?.isPermited ||
                            !permission.courses?.liveCourseHistory?.isPermited
                          }
                          value={permission.courses?.liveCourseHistory?.delete}
                          checked={permission.courses?.liveCourseHistory?.delete}
                          onChange={() => {
                            if (
                              permission.courses?.liveCourseHistory?.delete
                            ) {
                              updatePermission(
                                "permission.courses.liveCourseHistory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.courses.liveCourseHistory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>

                </FormGroup> */}

                {/* !--- Blogs---! */}
                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.blogs?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.blogs?.isPermited}
                          onChange={() => {
                            if (permission.blogs?.isPermited) {
                              updatePermission("permission.blogs", {
                                isPermited: false,
                                viewBlogs: {
                                  isPermited: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                  status: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.blogs.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontSize: "14px",
                          }}
                        >

                          Blogs
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.blogs?.viewBlogs?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.blogs?.isPermited}
                          checked={
                            permission.blogs?.viewBlogs?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.blogs?.viewBlogs?.isPermited
                            ) {
                              updatePermission(
                                "permission.blogs.viewBlogs",
                                {
                                  isPermited: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                  status: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.blogs.viewBlogs.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"View Blogs"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogs?.isPermited ||
                            !permission.blogs?.viewBlogs?.isPermited
                          }
                          value={permission.blogs?.viewBlogs?.edit}
                          checked={permission.blogs?.viewBlogs?.edit}
                          onChange={() => {
                            if (
                              permission.blogs?.viewBlogs?.edit
                            ) {
                              updatePermission(
                                "permission.blogs.viewBlogs.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogs.viewBlogs.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogs?.isPermited ||
                            !permission.blogs?.viewBlogs?.isPermited
                          }
                          value={permission.blogs?.viewBlogs?.delete}
                          checked={permission.blogs?.viewBlogs?.delete}
                          onChange={() => {
                            if (
                              permission.blogs?.viewBlogs?.delete
                            ) {
                              updatePermission(
                                "permission.blogs.viewBlogs.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogs.viewBlogs.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogs?.isPermited ||
                            !permission.blogs?.viewBlogs?.isPermited
                          }
                          checked={permission.blogs?.viewBlogs?.add}
                          onChange={() => {
                            if (
                              permission.blogs?.viewBlogs?.add
                            ) {
                              updatePermission(
                                "permission.blogs.viewBlogs.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogs.viewBlogs.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogs?.isPermited ||
                            !permission.blogs?.viewBlogs?.isPermited
                          }
                          checked={permission.blogs?.viewBlogs?.status}
                          onChange={() => {
                            if (
                              permission.blogs?.viewBlogs?.status
                            ) {
                              updatePermission(
                                "permission.blogs.viewBlogs.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogs.viewBlogs.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* !--- Blogs Category---! */}
                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.blogsCategory?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.blogsCategory?.isPermited}
                          onChange={() => {
                            if (permission.blogsCategory?.isPermited) {
                              updatePermission("permission.blogsCategory", {
                                isPermited: false,
                                edit: false,
                                delete: false,
                                add: false,
                              });
                            } else {
                              updatePermission(
                                "permission.blogsCategory.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontSize: "14px",
                          }}
                        >

                          Blogs Category
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogsCategory?.isPermited ||
                            !permission.blogsCategory?.isPermited
                          }
                          value={permission.blogsCategory?.edit}
                          checked={permission.blogsCategory?.edit}
                          onChange={() => {
                            if (
                              permission.blogsCategory?.edit
                            ) {
                              updatePermission(
                                "permission.blogsCategory.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogsCategory.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogsCategory?.isPermited ||
                            !permission.blogsCategory?.isPermited
                          }
                          value={permission.blogsCategory?.delete}
                          checked={permission.blogsCategory?.delete}
                          onChange={() => {
                            if (
                              permission.blogsCategory?.delete
                            ) {
                              updatePermission(
                                "permission.blogsCategory.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogsCategory.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.blogsCategory?.isPermited ||
                            !permission.blogsCategory?.isPermited
                          }
                          value={permission.blogsCategory?.add}
                          checked={permission.blogsCategory?.add}
                          onChange={() => {
                            if (
                              permission.blogsCategory?.add
                            ) {
                              updatePermission(
                                "permission.blogsCategory.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.blogsCategory.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>

                </FormGroup>

                {/* !--- Banners ---! */}

                <FormGroup aria-label="position" row>
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={permission.banners?.isPermited}
                          onChange={() => {
                            if (permission.banners?.isPermited) {
                              updatePermission("permission.banners", {
                                isPermited: false,
                                astrologerTrainingbanners: {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                },
                              });
                            } else {
                              updatePermission(
                                "permission.banners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#10395D",
                            fontSize: "14px",
                          }}
                        >
                          Banners
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Astrologer Training Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.astrologerTrainingbanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.astrologerTrainingbanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.astrologerTrainingbanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Astrologer Training Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerTrainingbanners?.isPermited
                          }
                          value={permission.banners?.astrologerTrainingbanners?.status}
                          checked={permission.banners?.astrologerTrainingbanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerTrainingbanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerTrainingbanners?.isPermited
                          }
                          value={permission.banners?.astrologerTrainingbanners?.edit}
                          checked={permission.banners?.astrologerTrainingbanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerTrainingbanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerTrainingbanners?.isPermited
                          }
                          value={permission.banners?.astrologerTrainingbanners?.delete}
                          checked={permission.banners?.astrologerTrainingbanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerTrainingbanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerTrainingbanners?.isPermited
                          }
                          checked={permission.banners?.astrologerTrainingbanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerTrainingbanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerTrainingbanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Astrologer Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.astrologerbanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.astrologerbanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.astrologerbanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.astrologerbanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerbanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Astrologer Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerbanners?.isPermited
                          }
                          value={permission.banners?.astrologerbanners?.status}
                          checked={permission.banners?.astrologerbanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerbanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.astrologerbanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerbanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerbanners?.isPermited
                          }
                          value={permission.banners?.astrologerbanners?.edit}
                          checked={permission.banners?.astrologerbanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerbanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.astrologerbanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerbanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerbanners?.isPermited
                          }
                          value={permission.banners?.astrologerbanners?.delete}
                          checked={permission.banners?.astrologerbanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerbanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.astrologerbanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerbanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.astrologerbanners?.isPermited
                          }
                          checked={permission.banners?.astrologerbanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.astrologerbanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.astrologerbanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.astrologerbanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Courses Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.coursesbanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.coursesbanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.coursesbanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.coursesbanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.coursesbanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Courses Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.coursesbanners?.isPermited
                          }
                          value={permission.banners?.coursesbanners?.status}
                          checked={permission.banners?.coursesbanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.coursesbanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.coursesbanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.coursesbanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.coursesbanners?.isPermited
                          }
                          value={permission.banners?.coursesbanners?.edit}
                          checked={permission.banners?.coursesbanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.coursesbanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.coursesbanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.coursesbanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.coursesbanners?.isPermited
                          }
                          value={permission.banners?.coursesbanners?.delete}
                          checked={permission.banners?.coursesbanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.coursesbanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.coursesbanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.coursesbanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.coursesbanners?.isPermited
                          }
                          checked={permission.banners?.coursesbanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.coursesbanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.coursesbanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.coursesbanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Redirect Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.redirectBanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.redirectBanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.redirectBanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.redirectBanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.redirectBanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Redirect Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.redirectBanners?.isPermited
                          }
                          value={permission.banners?.redirectBanners?.status}
                          checked={permission.banners?.redirectBanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.redirectBanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.redirectBanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.redirectBanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.redirectBanners?.isPermited
                          }
                          value={permission.banners?.redirectBanners?.edit}
                          checked={permission.banners?.redirectBanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.redirectBanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.redirectBanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.redirectBanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.redirectBanners?.isPermited
                          }
                          value={permission.banners?.redirectBanners?.delete}
                          checked={permission.banners?.redirectBanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.redirectBanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.redirectBanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.redirectBanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.redirectBanners?.isPermited
                          }
                          checked={permission.banners?.redirectBanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.redirectBanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.redirectBanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.redirectBanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Chat/Call  Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.callChatBanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.callChatBanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.callChatBanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.callChatBanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.callChatBanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Call/Chat Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.callChatBanners?.isPermited
                          }
                          value={permission.banners?.callChatBanners?.status}
                          checked={permission.banners?.callChatBanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.callChatBanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.callChatBanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.callChatBanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.callChatBanners?.isPermited
                          }
                          value={permission.banners?.callChatBanners?.edit}
                          checked={permission.banners?.callChatBanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.callChatBanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.callChatBanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.callChatBanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.callChatBanners?.isPermited
                          }
                          value={permission.banners?.callChatBanners?.delete}
                          checked={permission.banners?.callChatBanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.callChatBanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.callChatBanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.callChatBanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.callChatBanners?.isPermited
                          }
                          checked={permission.banners?.callChatBanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.callChatBanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.callChatBanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.callChatBanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Ecommerce  Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.ecommerceBanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.ecommerceBanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.ecommerceBanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.ecommerceBanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.ecommerceBanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Ecommerce Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.ecommerceBanners?.isPermited
                          }
                          value={permission.banners?.ecommerceBanners?.status}
                          checked={permission.banners?.ecommerceBanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.ecommerceBanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.ecommerceBanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.ecommerceBanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.ecommerceBanners?.isPermited
                          }
                          value={permission.banners?.ecommerceBanners?.edit}
                          checked={permission.banners?.ecommerceBanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.ecommerceBanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.ecommerceBanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.ecommerceBanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.ecommerceBanners?.isPermited
                          }
                          value={permission.banners?.ecommerceBanners?.delete}
                          checked={permission.banners?.ecommerceBanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.ecommerceBanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.ecommerceBanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.ecommerceBanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.ecommerceBanners?.isPermited
                          }
                          checked={permission.banners?.ecommerceBanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.ecommerceBanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.ecommerceBanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.ecommerceBanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* Product  Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.productBanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.productBanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.productBanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.productBanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.productBanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Product Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.productBanners?.isPermited
                          }
                          value={permission.banners?.productBanners?.status}
                          checked={permission.banners?.productBanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.productBanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.productBanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.productBanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.productBanners?.isPermited
                          }
                          value={permission.banners?.productBanners?.edit}
                          checked={permission.banners?.productBanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.productBanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.productBanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.productBanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.productBanners?.isPermited
                          }
                          value={permission.banners?.productBanners?.delete}
                          checked={permission.banners?.productBanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.productBanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.productBanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.productBanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.productBanners?.isPermited
                          }
                          checked={permission.banners?.productBanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.productBanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.productBanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.productBanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                {/* PoojaBanners  Banners */}
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <div className={classes.chips}>
                    <FormControlLabel
                      value={permission.banners?.poojaBanners?.isPermited}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={!permission.banners?.isPermited}
                          checked={
                            permission.banners?.poojaBanners?.isPermited
                          }
                          onChange={() => {
                            if (
                              permission.banners?.poojaBanners?.isPermited
                            ) {
                              updatePermission(
                                "permission.banners.poojaBanners",
                                {
                                  isPermited: false,
                                  status: false,
                                  edit: false,
                                  delete: false,
                                  add: false,
                                }
                              );
                            } else {
                              updatePermission(
                                "permission.banners.poojaBanners.isPermited",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Pooja Banners"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginLeft: "30px" }}
                >
                  <div>
                    <FormControlLabel
                      value={"Status"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.poojaBanners?.isPermited
                          }
                          value={permission.banners?.poojaBanners?.status}
                          checked={permission.banners?.poojaBanners?.status}
                          onChange={() => {
                            if (
                              permission.banners?.poojaBanners?.status
                            ) {
                              updatePermission(
                                "permission.banners.poojaBanners.status",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.poojaBanners.status",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Status"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Edit"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.poojaBanners?.isPermited
                          }
                          value={permission.banners?.poojaBanners?.edit}
                          checked={permission.banners?.poojaBanners?.edit}
                          onChange={() => {
                            if (
                              permission.banners?.poojaBanners?.edit
                            ) {
                              updatePermission(
                                "permission.banners.poojaBanners.edit",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.poojaBanners.edit",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Edit"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Delete"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.poojaBanners?.isPermited
                          }
                          value={permission.banners?.poojaBanners?.delete}
                          checked={permission.banners?.poojaBanners?.delete}
                          onChange={() => {
                            if (
                              permission.banners?.poojaBanners?.delete
                            ) {
                              updatePermission(
                                "permission.banners.poojaBanners.delete",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.poojaBanners.delete",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Delete"}
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value={"Add"}
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          disabled={
                            !permission.banners?.isPermited ||
                            !permission.banners?.poojaBanners?.isPermited
                          }
                          checked={permission.banners?.poojaBanners?.add}
                          onChange={() => {
                            if (
                              permission.banners?.poojaBanners?.add
                            ) {
                              updatePermission(
                                "permission.banners.poojaBanners.add",
                                false
                              );
                            } else {
                              updatePermission(
                                "permission.banners.poojaBanners.add",
                                true
                              );
                            }
                          }}
                        />
                      }
                      label={"Add"}
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>

              </FormControl>
              {error.permission && (
                <div className={classes.errorstyles}>{error.permission}</div>
              )}
            </Grid>
          )}

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <div onClick={handleSubmit} className={classes.submitbutton}>
              {isLoading ? <CircularProgress size={24} /> : "Submit"}
            </div>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <div onClick={handleReset} className={classes.denyButton}>
              Reset
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.gift.isLoading,
  adminData: state.admin.adminData,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(AddSubAdmin);
